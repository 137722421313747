import { render, staticRenderFns } from "./orderFiles.vue?vue&type=template&id=4bbe2cb6&scoped=true"
import script from "./orderFiles.vue?vue&type=script&lang=js"
export * from "./orderFiles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bbe2cb6",
  null
  
)

export default component.exports