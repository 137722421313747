<template>
  <div v-if="Object.keys(order).length">
    <bookingInfoCard :order="order">
      <ValidationSystems :order-id="order.id" :validity="order.validity" :showTextCaption="true" />
      <v-divider class="ml-3 mr-3 mb-5 mt-5" inset vertical></v-divider>
    </bookingInfoCard>
    <v-row class="mt-0">
      <v-col cols="12" sm="12" md="8">
        <invoiceInfo v-if="invoice" :invoice="invoice" class="mb-6"></invoiceInfo>
        <Passengers :passengers="order.passengers" />
        <div class="white elevation-1 rounded pa-5 pt-2 mt-2">
          <v-row>
            <v-col>
              <h4 class="mb-1 mt-3">Message External</h4>
              <vue-editor :editor-toolbar="customToolbar" v-model="order.externalMessage"> </vue-editor>
            </v-col>
            <v-col>
              <h4 class="mb-1 mt-3">Message Internal</h4>

              <div id="internalMessage">
                <vue-editor
                  :editor-toolbar="customToolbar"
                  style="background-color: #fff6d9"
                  v-model="order.internalMessage">
                </vue-editor>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right pt-0">
              <v-btn color="accent" @click="updateMessage()"> UPDATE </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-card
          v-if="account"
          style="padding: 1rem; box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px !important"
          class="mb-4">
          <h3 class="mango-red--text">
            Account Details
            <span style="font-size: 14px" class="accent--text mail-message" v-if="mailMessage"
              >( E-mail matching account found )</span
            >
          </h3>
          <v-row class="mt-2">
            <v-col cols="12" lg="12">
              <div class="d-flex gap-4 flex-wrap" style="gap: 10px">
                <h4 class="accent--text">Name:</h4>
                <span
                  >{{ account.firstName }}
                  {{ account.lastName }}
                </span>
              </div>
            </v-col>

            <v-col cols="12" lg="12">
              <div class="d-flex gap-4 flex-wrap" style="gap: 10px">
                <h4 class="accent--text">Email:</h4>
                <span>{{ account.email }} </span>
              </div>
            </v-col>

            <v-col cols="12" lg="12" v-if="account.phone">
              <div class="d-flex gap-4 flex-wrap" style="gap: 10px">
                <h4 class="accent--text">Phone:</h4>
                <span>{{ account.phone }} </span>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <Customer @updated="initial()" :order-id="order.id" :customer="order.customer" />
      </v-col>
    </v-row>

    <!-- Items -->
    <v-row>
      <v-col cols="12">
        <Items
          :initial="initial"
          :orderStatus="order.status"
          :orderId="order.id"
          :items="order.items"
          :printedItemIds="order.printedItemIds && order.printedItemIds"
          :order-is-paid="order.isPaid"
          :bookings="order.bookings"
          :base-currency="order.calculatedPrice.base.currency"
          :tenant-id="order.seller.tenant.id"
          :tenant-name="order.seller.tenant.name"
          :can-use-giftcard="order.seller.tenant.canUseGiftCards"
          :order-fully-paid="order.isFullyPaid"
          :locale="order.locale"
          v-on:itemChange="itemChange($event)" />
      </v-col>
    </v-row>

    <!-- Log -->
    <v-row justify="space-between">
      <v-col cols="12" lg="12">
        <header
          class="theme--light v-navigation-drawer elevation-1"
          style="height: 50px; border-radius: 5px; margin-bottom: 10px">
          <div class="v-toolbar__content" style="height: 50px; padding: 25px">
            <div class="v-toolbar__title">
              <h4 class="black--text"><span class="mango-red">Activity log </span>({{ order.events.length }})</h4>
            </div>
          </div>
        </header>
        <v-card>
          <v-card-text class="elevation-1">
            <v-timeline dense align-top>
              <v-timeline-item class="mb-12" color="secondary" icon="mdi-account" fill-dot large>
                <ValidationObserver ref="eventObserver">
                  <ValidationProvider v-slot="{ errors }" name="status" rules="required">
                    <v-text-field
                      v-model="event.comment"
                      :error-messages="errors"
                      label="Leave a comment..."
                      hide-details
                      solo>
                      <template v-slot:append>
                        <v-btn class="mx-0 accent" @click="createEvent()" :loading="event.loading" depressed
                          >Post
                        </v-btn>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </ValidationObserver>
              </v-timeline-item>

              <event-list :events="order.events" />
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="orderFilesDialog"
      :loading="isLoading"
      width="500px"
      class="pa-5"
      @click:outside="orderFilesDialog = false">
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title class="ml-2">Order Files</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="orderFilesDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <orderFiles :files="orderFilesData" :key="Math.random()" />
      </v-card>
    </v-dialog>
    <!-- Buttons -->
    <float-buttons
      :isCompany="order.invoiceId"
      :customer="order.customer"
      :order-id="orderId"
      :order-is-paid="order['isPaid']"
      :order-is-fully-paid="order['isFullyPaid']"
      :order-is-anonymized="order['isAnonymized']"
      :is-archived-order="order.archived"
      :order-contains-rail-passes="includesRailPass(order.items)"
      @get-files="getOrderFiles($event)"
      @initials="initial" />
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import common from "../../../mixins/common";
import store from "@/store";
import router from "@/router";
import AuthApi from "@/services/auth.api";
import invoiceInfo from "@/components/info/invoiceInfo";
import bookingInfoCard from "@/components/info/bookingInfoCard";
import Customer from "../components/Customer.vue";
import EventList from "../components/EventList";
import Items from "../components/Items.vue";
import orderFiles from "@/components/info/orderFiles";
import Passengers from "../components/Passengers.vue";
import FloatButtons from "./partials/FloatButtons";
import { copyToClipboard } from "@/util/helpers";
import { VueEditor } from "vue2-editor";
import companyDetail from "@/components/info/companyDetail";
import ValidationSystems from "@/views/Orders/Order/ValidationSystems.vue";

export default {
  name: "Edit",
  mixins: [common],
  props: {
    orderId: String,
  },
  components: {
    ValidationSystems,
    VueEditor,
    orderFiles,
    companyDetail,
    bookingInfoCard,
    invoiceInfo,
    Items,
    ValidationProvider,
    ValidationObserver,
    Passengers,
    Customer,
    FloatButtons,
    EventList,
  },

  data: () => ({
    mailMessage: false,
    account: null,
    orderFilesData: null,
    orderFilesDialog: false,
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      [{ color: [] }],
    ],
    isLoading: false,
    order: {},
    companyDetail: null,
    invoice: null,
    event: {
      loading: false,
      comment: null,
    },
  }),
  async mounted() {
    await this.initial();
  },
  watch: {
    order: {
      handler: function (val) {
        if (!val?.customer?.accountInfo?.id) {
          this.mailMessage = true;
        }
      },
    },
  },
  computed: {
    AuthModel() {
      return new AuthApi(this.tenant, this.token);
    },
    isExpiring() {
      const tomorrow = moment().add(1, "days").startOf("day");
      return moment(this.order.expires).isSame(tomorrow);
    },
    hasExpired() {
      return moment(this.order.expires) < moment();
    },
    hasInquiry() {
      return this.order.events.find((x) => x.code === "INQUIRY_CREATED") !== undefined;
    },
  },
  methods: {
    async getTenant() {
      const res = await this.$store.dispatch("auth/getUser");
      this.allTenants = res.tenants;
    },
    async getToken() {
      this.token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
      this.getOrderAcc();
    },
    async getOrderAcc() {
      await Trainplanet.getOrderAccount(this.orderId)
        .then((res) => {
          if (res) {
            this.account = res;
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.dispatch("loading", false);
        });
    },
    async getOrderFiles(lang) {
      await this.$store.dispatch("loading", true);
      await Trainplanet.getOrderFiles(this.orderId, lang)
        .then((res) => {
          this.orderFilesData = res;
          this.orderFilesDialog = true;
        })
        .catch((error) => {
          this.$store.dispatch("error", error.message);
        })
        .finally(() => {
          this.$store.dispatch("loading", false);
        });
    },
    async updateMessage() {
      this.$confirm("You are about to change internal & external message now, do you want to continue?", {
        color: "primary",
        title: "Update Information",
      })
        .then(async (res) => {
          if (!res) return;
          this.isLoading = true;
          let id = this.orderId;
          await Trainplanet.updateOrder(id, {
            internalMessage: this.order.internalMessage,
            externalMessage: this.order.externalMessage,
          })
            .then((res) => {
              this.$store.dispatch("success", "Messages Updated");
            })
            .catch((error) => {
              console.error(error);
              if (error.message) {
                this.$store.dispatch("error", error.message);
              }
            })
            .finally((r) => {
              this.isLoading = false;
            });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async initCustomersByCompanyId(companyId) {
      try {
        this.isLoading = true;
        const res = await Trainplanet.getCustomerList({ companyId });
        this.companyDetail.customers = res.customers;
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message);
      } finally {
        this.isLoading = false;
      }
    },
    groupedByExternalSystem(items = []) {
      return items.reduce((acc, curr) => {
        const externalSystem = acc.find((item) => curr.externalSystem === item.externalSystem);
        if (externalSystem) {
          externalSystem.groupedExternalIds.push(curr.externalOrderId);
        } else {
          acc.push({ ...curr, groupedExternalIds: [curr.externalOrderId] });
        }
        return acc;
      }, []);
    },
    includesRailPass(items = []) {
      for (const item of items) {
        if (item.type === "railpass") {
          return true;
        }
      }
      return false;
    },
    copyToClipboard,
    async initial() {
      await this.$store.dispatch("loading", true);
      await Trainplanet.getOrder(this.orderId)
        .then((res) => {
          this.order = res;

          this.getTenant();
          this.getToken();
          if (this.order.items.length > 0) {
            this.order.items.forEach((element) => {
              element.selectable =
                element.status === "pending" && ["article", "distribution", "giftcard"].includes(element.type);
            });
          }
        })
        .catch((error) => {
          this.$store.dispatch("error", error.response.data.message || error.message || error);
        })
        .finally(() => {
          this.$store.dispatch("loading", false);
        });

      if (this.order.invoiceId) {
        await this.$store.dispatch("loading", true);
        await Trainplanet.getInvoice(this.order.invoiceId)
          .then((res) => {
            this.invoice = res;
            this.initCustomersByCompanyId(this.invoice.company.id);
            this.companyDetail = this.invoice.company;
          })
          .catch((error) => {
            // this.$store.dispatch("error", error.message);
            this.invoice = null;
            this.companyDetail = null;
          })
          .finally(() => {
            this.$store.dispatch("loading", false);
          });
      }
    },
    async createEvent() {
      this.$refs.eventObserver.validate().then((res) => {
        if (!res) return;
        this.event.loading = true;
        Trainplanet.createOrderEvent(this.orderId, this.event.comment)
          .then((res) => {
            this.event.comment = null;
            this.order.events.unshift(res);
          })
          .catch((error) => {
            this.$store.dispatch("error", error.message);
          })
          .finally(() => {
            this.event.loading = false;
          });
      });
    },
    itemChange(newValue) {
      this.order = newValue.data;
      this.initial();
    },
  },
};
</script>
<style></style>
