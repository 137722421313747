<template>
  <div>
    <v-speed-dial v-model="fab" direction="left" bottom right fixed>
      <template v-slot:activator>
        <v-btn v-model="fab" large color="accent" dark fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-file-document-edit</v-icon>
        </v-btn>
      </template>

      <!-- Payment Link -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              :disabled="!customer || orderIsFullyPaid"
              :dark="!(!customer || orderIsFullyPaid)"
              @click="openModal('sendOrderPaylink', 'payment', true)"
              fab
              color="info"
            >
              <v-icon>mdi-cash-multiple</v-icon>
            </v-btn>
          </div>
        </template>

        <span>(Re)Send Order Payment/Offer</span>
      </v-tooltip>

      <!-- Order Confirmation -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              :disabled="!customer || !orderIsPaid"
              :dark="!(!customer || !orderIsPaid)"
              @click="
                openModal('sendConfirmationLink', 'order confirmation', true)
              "
              fab
              color="pink"
            >
              <v-icon>mdi-email</v-icon>
            </v-btn>
          </div>
        </template>

        <span>(Re)Send Order Confirmation</span>
      </v-tooltip>

      <!-- Send Tickets -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              :disabled="!customer || !orderIsPaid"
              :dark="!(!customer || !orderIsPaid)"
              @click="openModal('sendTicket', 'tickets', false)"
              fab
              color="green"
            >
              <v-icon>mdi-ticket-confirmation</v-icon>
            </v-btn>
          </div>
        </template>

        <span>(Re)Send Tickets</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="inquiryModal = true"
            fab
            dark
            color="purple"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-cash-plus</v-icon>
          </v-btn>
        </template>

        <span>Create Claim Ticket</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="updatelock('unlock')"
            fab
            dark
            color="indigo"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-lock-off-outline</v-icon>
          </v-btn>
        </template>
        <span>Unlock Order</span>
      </v-tooltip>

      <v-tooltip top v-if="isCompany">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              @click="debitOrderModal = true"
              fab
              color="danger"
              :disabled="orderIsFullyPaid || !userCanInvoice"
              :dark="!(orderIsPaid || !userCanInvoice)"
            >
              <v-icon color="white">mdi-cash-check</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Debit the Order via Invoice </span>
      </v-tooltip>

      <v-tooltip top v-if="userRoles.includes('Role.Admin')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="anonymizeOrder"
            fab
            color="blue-grey"
            v-bind="attrs"
            v-on="on"
            :disabled="orderIsAnonymized"
            :dark="!orderIsAnonymized"
          >
            <v-icon>mdi-incognito</v-icon>
          </v-btn>
        </template>

        <span>Anonymize Order</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              @click="archiveOrder()"
              fab
              :disabled="orderIsPaid"
              :dark="!(orderIsPaid)"
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="isArchivedOrder">mdi-archive-off</v-icon>
              <v-icon v-else>mdi-archive-arrow-down</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ isArchivedOrder ? 'Unarchive order' : 'Archive order' }}</span>
      </v-tooltip>
    </v-speed-dial>
    <!-- send pay / confirm link -->
    <v-dialog v-model="sendLinkModal" max-width="700px">
      <v-overlay
        :value="$store.state.loading"
        absolute
        color="white"
        :opacity="0.8"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="accent"
        ></v-progress-circular>
      </v-overlay>
      <v-container>
        <v-card :disabled="$store.state.loading">
          <v-flex class="mb-12"></v-flex>
          <base-material-card color="warning" icon="mdi-alert-circle-outline">
            <template v-slot:after-heading>
              <div class="ml-auto body-3">
                Send {{ dialogTitle }}
                <h3>
                  Are you sure to send the {{ dialogTitle }} to the customer?
                </h3>
              </div>
            </template>
            <v-divider class="mt-2"/>
            <v-card-text class="pb-0">
              <div
                v-if="
                  dialogTitle === 'order confirmation' &&
                  orderContainsRailPasses
                "
                class="text-h6 mb-2 font-weight-bold"
              >
                <!-- TODO "gift cards will be attached to the confirmation e-mail" message -->
                Interrail passes will be attached to the e-mail.
              </div>
              <div v-if="dialogTitle === 'tickets'">
                <p class="subtitle-1 font-weight-bold">
                  Tickets will be sent to the e-mail address/phone number of
                  each passenger.
                </p>

                <div
                  v-if="orderContainsRailPasses"
                  class="text-h6 font-weight-bold blue--text"
                >
                  For Interrail passes, the order confirmation mail will be
                  re-sent automatically.
                </div>
              </div>
              <div v-else>
                <v-row>
                  <v-col v-if="customer" cols="12" md="8" class="">
                    Customer's E-mail: <b> {{ customer.email }}</b>
                  </v-col>

                  <v-col v-if="this.isactiveLang" cols="12" md="4" class="mb-0">
                    <v-select
                      v-model="language"
                      :items="languages"
                      item-value="value"
                      persistent-hint
                      label="Select language"
                      return-object
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="
                      dialogTitle === 'payment' &&
                      (isCompany || customer.company)
                    "
                  >
                    <!-- TODO force enable offerMode if customer.company exists-->
                    <v-switch
                      v-model="offerMode"
                      :label="`Offer Only`"
                      :messages="`Only the order details/preview will be shown, and there will be no payment options. Order will have to be debited manually via invoice through Mango if the customer accepts the offer.`"
                      :readonly="offerMode"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <template v-slot:actions>
              <v-btn color="red" outlined @click="sendLinkModal = false" x-large
              >Not Now
              </v-btn
              >
              <v-spacer></v-spacer>
              <v-btn v-if="sendLinkModal && dialogMethod !== 'sendOrderPaylink'" @click="$emit('get-files', language.value ? language.value : language)"
                     color="accent" x-large
              >View Tickets
              </v-btn>
              <v-btn @click="sendAction" color="success" x-large
              >Send
              </v-btn>
            </template>
          </base-material-card>
        </v-card>
      </v-container>
    </v-dialog>

    <!-- create inquiry modal  -->
    <v-dialog v-model="inquiryModal" max-width="700px">
      <v-container>
        <v-card :disabled="$store.state.loading">
          <v-flex class="mb-12"></v-flex>
          <base-material-card color="warning" icon="mdi-alert-circle-outline">
            <template v-slot:after-heading>
              <div class="ml-auto">
                <div
                  class="body-3 grey--text font-weight-light"
                  v-text="'Create a claim'"
                />
                <h3>Create a claim</h3>
              </div>
            </template>
            <v-divider class="mt-2"/>

            <v-card-text>
              <ValidationObserver ref="inquiryObserver" v-slot="{Validate, reset}">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="description"
                  rules="required"
                >
                  <v-textarea
                    filled
                    label="Description"
                    v-model="inquiryDesc"
                    :error-messages="errors"
                    clearable
                  />
                </ValidationProvider>
              </ValidationObserver>
            </v-card-text>
            <template v-slot:actions>
              <v-btn color="red" outlined @click="inquiryModal = false" x-large
              >Not Now
              </v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                :loading="$store.state.loading"
                @click="createInquiry"
                color="success"
                x-large
              >Create Claim
              </v-btn>
            </template>
          </base-material-card>
        </v-card>
      </v-container>
    </v-dialog>

    <!-- debit via invoice to company modal  -->
    <v-dialog v-model="debitOrderModal" max-width="700px" v-if="isCompany">
      <div>
        <v-card :disabled="$store.state.loading" class="elevation-0">
          <v-flex class="pb-12"></v-flex>
          <base-material-card color="warning" icon="mdi-alert-circle-outline">
            <template v-slot:after-heading>
              <div class="ml-auto">
                <div
                  style="margin-top: -20px"
                  class="body-3 grey--text font-weight-light"
                  v-text="'Debit the order'"
                />
                <h3>
                  <!-- Debit the order with invoice to
                  <span color="text--danger">{{ customer.company.name }}</span> -->
                </h3>
              </div>
            </template>

            <v-divider class="mt-2"/>

            <v-card-text>
              <h2 class="mb-2">
                <!-- The company has to pay the invoice in {{ customer.company.allowedDaysToPay }} days! -->
              </h2>
              <h3>
                The confirmation mail and tickets WILL be sent automatically.
              </h3>
            </v-card-text>
            <template v-slot:actions>
              <v-btn
                color="red"
                outlined
                @click="debitOrderModal = false"
                x-large
              >Not Now
              </v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                :loading="$store.state.loading"
                @click="debitTheOrder"
                color="success"
                x-large
              >Debit
              </v-btn>
            </template>
          </base-material-card>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {mapGetters} from "vuex";
import {ROLES} from "@/store/modules/roles";

export default {
  name: "FloatButtons",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isArchivedOrder: {
      required: true,
    },
    isCompany: null,
    orderId: {
      required: true,
    },
    customer: {
      required: true,
      default: null,
    },
    orderIsPaid: {
      required: true,
    },
    orderIsFullyPaid: {
      required: true,
    },
    orderIsAnonymized: {
      required: true,
    },
    orderContainsRailPasses: {
      required: true,
    },
  },
  data() {
    return {
      isArchivedOrderInternal: this.isArchivedOrder,
      debitOrderModal: false,
      fab: false,
      sendLinkModal: false,
      inquiryModal: false,
      isactiveLang: false,
      //TODO change it to system default lang
      language: "sv-SE",
      dialogMethod: null,
      dialogTitle: "",
      inquiryDesc: "",
      languages: [],
      offerMode: this.isCompany != null,
    };
  },
  computed: {
    ...mapGetters({
      userRoles: "auth/userRoles",
      userCanInvoice: "auth/canInvoiceOrders",
    }),
  },
  methods: {
    async debitTheOrder() {
      try {
        await this.$store.dispatch("loading", true);
        const res = await Trainplanet.getPaymentSession(this.orderId);
        await Trainplanet.completePaymentSession(res.pushUrl);

        await this.$router.push({
          name: "invoices",
        });
      } catch (error) {
        await this.$store.dispatch(
          "error",
          error.response.data.message || error
        );
      } finally {
        await this.$store.dispatch("loading", false);
      }
    },
    async sendAction() {
      try {
        await this.$store.dispatch("loading", true);
        await Trainplanet[this.dialogMethod](
          this.orderId,
          this.language,
          this.offerMode
        );
        await this.$store.dispatch(
          "success",
          this.dialogTitle + " has been sent."
        );
        this.sendLinkModal = false;
      } catch (error) {
        let message = error.message;
        if (error.response) {
          message = error.response.data.message;
        }
        await this.$store.dispatch("error", message);
      } finally {
        await this.$store.dispatch("loading", false);
        this.$emit("initials");
      }
    },
    openModal(method, title, lang) {
      this.offerMode = this.isCompany != null;
      this.dialogTitle = title;
      this.dialogMethod = method;
      this.sendLinkModal = true;
      this.isactiveLang = lang;
    },
    async updatelock(method, title) {
      this.$confirm(
        "Are you sure that you want to " + method + " this order?",
        {
          color: "primary",
          title: method + " Order",
        }
      ).then(async (res) => {
        if (!res) return;
        try {
          await this.$store.dispatch("loading", true);
          await Trainplanet[method](this.orderId);
          await this.$store.dispatch(
            "success",
            "Order has been " + method + "ed."
          );
        } catch (error) {
          let message = error.message;
          if (error.response) {
            message = error.response.data.message;
          }
          await this.$store.dispatch("error", message);
        } finally {
          await this.$store.dispatch("loading", false);
          this.$emit("initials");
        }
      });
    },
    async archiveOrder() {
      const action = this.isArchivedOrder ? "unarchive" : "archive";
      const confirmTitle = this.isArchivedOrder ? "Unarchive Order" : "Archive Order";
      const successMessage = this.isArchivedOrder ? "Order has been unarchived." : "Order has been archived.";
      const successIcon = !this.isArchivedOrder ? "mdi-archive-arrow-down" : "mdi-archive-off";
      this.$confirm(`Are you sure that you want to ${action} this order?`, {
        color: "red",
        title: confirmTitle,
        icon: successIcon
      }).then(async (res) => {
        if (!res) return;
        try {
          await this.$store.dispatch("loading", true);
          const result = await Trainplanet.archiveOrder(this.orderId);
          this.isArchivedOrderInternal = result.newArchivedStatus;
          await this.$store.dispatch("success", successMessage);
          this.$emit("initials");
        } catch (error) {
          let message = error.message;
          if (error.response) {
            message = error.response.data.message;
          }
          await this.$store.dispatch("error", message);
        } finally {
          await this.$store.dispatch("loading", false);
        }
      });
    },
    createInquiry() {
      this.$refs.inquiryObserver.validate().then((res) => {
        if (!res) return;
        const input = {
          orders: [this.orderId],
          description: this.inquiryDesc,
        };
        this.$store.dispatch("loading", true);
        Trainplanet.createInquiry(input)
          .then((data) => {
            this.$router.push({
              name: "inquiries-edit",
              params: {inquiryId: data.id},
            });
          })
          .catch((error) => {
            let message = error.message;
            if (error.response) {
              message = error.response.data.message;
            }
            this.$store.dispatch("error", message);
          })
          .finally(() => {
            this.$store.dispatch("loading", false);
          });
      });
    },
    anonymizeOrder() {
      this.$confirm("Are you sure that you want to anonymize this order?", {
        color: "red",
        title: "Anonymize Order",
      }).then(async (res) => {
        if (!res) return;

        try {
          await this.$store.dispatch("loading", true);
          await Trainplanet.anonymizeOrder(this.orderId);
          await this.$store.dispatch(
            "success",
            "Order has been successfully anonymized."
          );
        } catch (error) {
          await this.$store.dispatch(
            "error",
            error.response.data.message || error.message
          );
        } finally {
          await this.$store.dispatch("loading", false);
          this.$emit("initials");
        }
      });
    },
    async getLocals() {
      await Trainplanet.getOrderSystemOptions()
        .then((res) => {
          for (const [local, text] of Object.entries(res.locales)) {
            this.languages.push({
              text: text,
              value: local,
            });
          }
        })
        .catch((error) => {
          let message = error.message;
          if (error.response) {
            message = error.response.data.message;
          }
          this.$store.dispatch("error", message);
        });
    },
  },
  created() {
    this.getLocals();
  },
};
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>
