<template>
  <div>
    <div class="elevation-1 rounded pa-5 white">
      <v-row class="align-center">
        <v-col md="auto">
          <div class="d-flex align-center">
            <h1 class="secondary--text">
              {{ order.id }}
            </h1>
            <OrderIconsField
              :order="order"
              :showPrintableInfo="true"
              :showDistributedInfo="true"
              :showGroupOrderInfo="true"
              :showClaimInfo="false"
              :showArchiveOrder="true">
            </OrderIconsField>
            <v-btn
              v-if="order.inquiries && order.inquiries.length"
              @click="navInquiries(order.inquiries)"
              class="ml-2"
              small
              color="accent">
              HAS CLAIM TICKET
            </v-btn>
          </div>

          <div class="caption secondary--text">
            Created
            <code class="text-caption"> {{ order.created | getOnlyDate }} {{ order.created | getOnlyTime }} </code>
            (GMT+{{ getGmtDiff }}) by
            <code class="text-caption">
              {{ order.seller.user.fullName ?? order.seller.user.name }}
            </code>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <!-- These slots for validation systems. -->
        <slot name="default"></slot>
        <slot name="dialog"></slot>
        <v-col md="auto">
          <h3 class="warning--text text--lighten-1">
            {{ order.cancelInformation.amount.plainText }}
          </h3>
          <div class="text-caption">Refunded order amount</div>
        </v-col>

        <v-col md="auto">
          <h3>
            {{ order.calculatedPrice.total.plainText }}
          </h3>
          <div class="text-caption">Total order amount</div>
        </v-col>

        <v-col md="auto">
          <h3 :class="[order.calculatedPrice.balance.amount < 0 ? 'red--text' : 'green--text']">
            {{ order.calculatedPrice.balance.plainText }}
          </h3>
          <div class="text-caption">Order payment balance</div>
        </v-col>
      </v-row>
    </div>

    <v-toolbar class="elevation-1 rounded mt-2 white">
      <div class="d-flex">
        <span class="font-weight-bold secondary--text"
          ><img width="20px" class="mr-1" src="@/assets/linkonline.svg" />Linkonline :
          <span v-if="order.bookings.length < 1" class="grey--text"> No Linkonline Booking</span>
        </span>
        <div
          v-for="booking in groupedByExternalSystem(order.bookings)"
          :key="booking.id"
          class="d-flex align-center ml-2">
          <span class="font-weight-black secondary--text" v-for="(id, _) in booking.groupedExternalIds" :key="id"
            >{{ id }}
            <v-tooltip top :open-delay="1000" :close-delay="500" :open-on-hover="false">
              <template v-slot:activator="{ on }">
                <v-icon color="mango-red" @click="copyToClipboard(id)" small v-on="on"> mdi-content-copy </v-icon>
              </template>
              <span>Copied</span>
            </v-tooltip>
            <span v-if="booking.groupedExternalIds.length - 1 !== _" class="black--text mx-1"> / </span>
          </span>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-title class="font-weight-bold black--text d-flex align-center">
        <img
          width="26px"
          class="mr-2"
          :src="
            'https://assets.trainplanet.com/wlabel/' + fixUrl(order.seller.tenant.name) + '/logos/favicon-32x32.png'
          " />
        {{ order.seller.tenant.name }}
      </v-toolbar-title>
    </v-toolbar>
  </div>
</template>

<script>
import { copyToClipboard, fixUrl } from "@/util/helpers";
import toolbarTitle from "@/components/common/toolbarTitle";
import OrderIconsField from "@/components/common/OrderIconsField";
import common from "@/mixins/common";
import moment from "moment";

export default {
  components: {
    OrderIconsField,
    toolbarTitle,
    copyToClipboard,
  },
  mixins: [common],
  computed: {
    getGmtDiff() {
      return moment().utcOffset() / 60;
    },
  },
  filters: {
    humanDatetime(datetime) {
      return moment.utc(datetime).local().fromNow();
    },
    getOnlyDate(datetime) {
      return moment.utc(datetime).local().format("YYYY-MM-DD");
    },
    getOnlyTime(datetime) {
      // get gmt offset
      return moment.utc(datetime).local().format("HH:mm");
    },
  },
  methods: {
    navInquiries(value) {
      window.open("/orders/inquiries/" + value);
    },
    copyToClipboard,
    fixUrl,
    groupedByExternalSystem(items = []) {
      return items.reduce((acc, curr) => {
        const externalSystem = acc.find((item) => curr.externalSystem === item.externalSystem);
        if (externalSystem) {
          externalSystem.groupedExternalIds.push(curr.externalOrderId);
        } else {
          acc.push({ ...curr, groupedExternalIds: [curr.externalOrderId] });
        }
        return acc;
      }, []);
    },
  },
  props: {
    order: [],
  },
};
</script>
