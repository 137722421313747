var render = function render(){
  var _vm$invoice$debitedBy;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('toolbarTitle', {
    staticClass: "mb-5",
    attrs: {
      "title": _vm.invoice.fortnoxNumber ? 'Invoice Info #' + _vm.invoice.fortnoxNumber : 'Invoice Info',
      "titleRed": `(` + _vm.statusList.find(function (item) {
        return item.id === _vm.invoice.status;
      }).label + `)`
    }
  }), _c('div', {
    staticClass: "elevation-1 rounded"
  }, [_vm.invoice ? _c('v-card', {
    staticClass: "pa-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v("Company Name")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pb-0"
  }, [_c('v-icon', {
    staticClass: "pa-1",
    attrs: {
      "color": "primary",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.companyDetail = _vm.invoice.company;
        _vm.initCustomersByCompanyId(_vm.invoice.company.id);
        _vm.detailDialog = true;
      }
    }
  }, [_vm._v(" mdi-domain ")]), _vm._v(" " + _vm._s(_vm.invoice.company.name ? _vm.invoice.company.name : "---"))], 1)], 1), _c('v-col', {
    staticClass: "py-0",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v("Customer")])]), _vm.invoice.customer ? _c('v-card-subtitle', {
    staticClass: "font-weight-bold pb-0"
  }, [_c('v-tooltip', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "color": "black",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('b', _vm._g(_vm._b({}, 'b', attrs, false), on), [_vm._v(_vm._s(_vm.invoice.customer.companyCustomerNumber ? "IATA Number " + _vm.invoice.customer.companyCustomerNumber : "") + " " + _vm._s(_vm.invoice.customer.firstName) + " " + _vm._s(_vm.invoice.customer.lastName) + " ")]), _c('div', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.invoice.customer.email) + " ")])];
      }
    }], null, false, 3294216412)
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.invoice.customer.email) + " ")])])], 1) : _c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_c('v-tooltip', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "color": "black",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('b', _vm._g(_vm._b({}, 'b', attrs, false), on), [_vm._v(_vm._s(_vm.invoice.order.customer.firstName) + " " + _vm._s(_vm.invoice.order.customer.lastName))])];
      }
    }], null, false, 1782794127)
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.invoice.order.customer.email) + " ")])])], 1)], 1), _c('v-col', {
    staticClass: "py-0",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(" Order Debited (GMT +" + _vm._s(_vm.gmt) + ")")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pb-0"
  }, [_vm._v(" " + _vm._s(_vm._f("localizeUTCAndGetDate")(_vm.invoice.created)) + " "), _c('v-icon', {
    staticClass: "third--text clock mb-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-clock-time-eight-outline")]), _vm._v(" " + _vm._s(_vm._f("localizeUTCAndGetTime")(_vm.invoice.created)) + " "), _vm.invoice.debitedBy ? _c('div', {
    staticClass: "text-body-2"
  }, [_vm._v(" by " + _vm._s(_vm.invoice.debitedBy ? (_vm$invoice$debitedBy = _vm.invoice.debitedBy.fullName) !== null && _vm$invoice$debitedBy !== void 0 ? _vm$invoice$debitedBy : _vm.invoice.debitedBy.name : "") + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(" Must be Paid Before")])]), _vm.invoice.invoicedInExternalSystem ? _c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm._f("localizeUTCAddDaysAndGetDate")(_vm.invoice.invoicedInExternalSystem, _vm.invoice.company.allowedDaysToPay)))]) : _c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Not invoiced yet ")])], 1), _c('v-col', {
    staticClass: "py-0",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(" Reference")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.invoice.reference ? _vm.invoice.reference : "-"))])], 1), _c('v-col', {
    staticClass: "py-0",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(" Invoice Events ")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_c('v-tooltip', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "color": "black",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "font-weight-medium one-line threeDots",
          staticStyle: {
            "max-width": "250px"
          }
        }, 'div', attrs, false), on), [_c('v-icon', {
          staticClass: "pa-1",
          attrs: {
            "small": "",
            "color": "primary "
          },
          on: {
            "click": function ($event) {
              _vm.eventDetail = _vm.invoice;
            }
          }
        }, [_vm._v("mdi-history")]), _vm._v(" " + _vm._s(_vm.lastEvent(_vm.invoice.events[0]).description) + " ")], 1)];
      }
    }], null, false, 1419035288)
  }, [_c('div', [_vm._v(_vm._s(_vm.lastEvent(_vm.invoice.events[0]).description))])])], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('v-dialog', {
    staticClass: "pa-5",
    attrs: {
      "loading": _vm.isLoading,
      "width": "1000px"
    },
    on: {
      "click:outside": function ($event) {
        _vm.detailDialog = false;
      }
    },
    model: {
      value: _vm.detailDialog,
      callback: function ($$v) {
        _vm.detailDialog = $$v;
      },
      expression: "detailDialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "ml-2"
  }, [_vm._v("Company : " + _vm._s(_vm.companyDetail && _vm.companyDetail.name))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.detailDialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _vm.companyDetail ? _c('companyDetail', {
    attrs: {
      "company": _vm.companyDetail,
      "loading": _vm.isLoading
    }
  }) : _vm._e()], 1)], 1), _vm.eventDetail ? _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    on: {
      "click:outside": function ($event) {
        _vm.eventDetail = false;
      }
    },
    model: {
      value: _vm.eventDetail,
      callback: function ($$v) {
        _vm.eventDetail = $$v;
      },
      expression: "eventDetail"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Invoice Events History")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.eventDetail = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "px-4 py-4"
  }, [_c('v-row', [_c('v-col', [_c('v-card-text', {
    staticClass: "py-0 elevation-2 rounded-lg",
    staticStyle: {
      "max-height": "500px",
      "overflow": "auto"
    }
  }, [_c('v-timeline', {
    attrs: {
      "align-top": "",
      "dense": ""
    }
  }, _vm._l(_vm.eventDetail.events, function (event, index) {
    var _event$user$fullName;
    return _c('v-timeline-item', {
      key: index,
      attrs: {
        "small": ""
      }
    }, [_c('v-row', {
      staticClass: "pt-1"
    }, [_c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('strong', [_vm._v(_vm._s(event.datetime))])]), _c('v-col', [_c('strong', [_vm._v(_vm._s((_event$user$fullName = event.user.fullName) !== null && _event$user$fullName !== void 0 ? _event$user$fullName : event.user.name))]), _c('div', {
      staticClass: "text-caption"
    }, [_vm._v(" " + _vm._s(event.description) + " ")])])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }