var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-4"
  }, [_vm.sortedTickets && _vm.sortedTickets.length > 0 ? _c('v-card', [_c('toolbarTitle', {
    attrs: {
      "title": "Order Tickets"
    }
  }), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.sortedTickets, function (item, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-list-item-content', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(item.category == "ticket" ? "Ticket" + " " + item.fileName : _vm.fixFilename(item.fileName)))]), _c('v-list-item-content', {
      staticClass: "justify-end"
    }, [_c('v-btn', {
      staticClass: "white--text",
      staticStyle: {
        "max-width": "130px"
      },
      attrs: {
        "color": "accent"
      },
      on: {
        "click": function ($event) {
          return _vm.openFile(item.accessUrl);
        }
      }
    }, [_vm._v(" View File "), _c('v-icon', {
      attrs: {
        "right": "",
        "dark": ""
      }
    }, [_vm._v(" mdi-eye ")])], 1)], 1)], 1);
  }), 1)], 1) : _vm._e(), _vm.sortedFiles && _vm.sortedFiles.length > 0 ? _c('v-card', [_c('toolbarTitle', {
    attrs: {
      "title": "Order Files"
    }
  }), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.sortedFiles, function (item, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-list-item-content', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(item.category == "ticket" ? "Ticket" + " " + item.fileName : _vm.fixFilename(item.fileName)))]), _c('v-list-item-content', {
      staticClass: "justify-end"
    }, [_c('v-btn', {
      staticClass: "white--text",
      staticStyle: {
        "max-width": "130px"
      },
      attrs: {
        "color": "accent"
      },
      on: {
        "click": function ($event) {
          return _vm.openFile(item.accessUrl);
        }
      }
    }, [_vm._v(" View File "), _c('v-icon', {
      attrs: {
        "right": "",
        "dark": ""
      }
    }, [_vm._v(" mdi-eye ")])], 1)], 1)], 1);
  }), 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }