var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-speed-dial', {
    attrs: {
      "direction": "left",
      "bottom": "",
      "right": "",
      "fixed": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "large": "",
            "color": "accent",
            "dark": "",
            "fab": ""
          },
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v;
            },
            expression: "fab"
          }
        }, [_vm.fab ? _c('v-icon', [_vm._v("mdi-close")]) : _c('v-icon', [_vm._v("mdi-file-document-edit")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.fab,
      callback: function ($$v) {
        _vm.fab = $$v;
      },
      expression: "fab"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', {
          attrs: {
            "disabled": !_vm.customer || _vm.orderIsFullyPaid,
            "dark": !(!_vm.customer || _vm.orderIsFullyPaid),
            "fab": "",
            "color": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.openModal('sendOrderPaylink', 'payment', true);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-cash-multiple")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v("(Re)Send Order Payment/Offer")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', {
          attrs: {
            "disabled": !_vm.customer || !_vm.orderIsPaid,
            "dark": !(!_vm.customer || !_vm.orderIsPaid),
            "fab": "",
            "color": "pink"
          },
          on: {
            "click": function ($event) {
              return _vm.openModal('sendConfirmationLink', 'order confirmation', true);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-email")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v("(Re)Send Order Confirmation")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', {
          attrs: {
            "disabled": !_vm.customer || !_vm.orderIsPaid,
            "dark": !(!_vm.customer || !_vm.orderIsPaid),
            "fab": "",
            "color": "green"
          },
          on: {
            "click": function ($event) {
              return _vm.openModal('sendTicket', 'tickets', false);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-ticket-confirmation")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v("(Re)Send Tickets")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "fab": "",
            "dark": "",
            "color": "purple"
          },
          on: {
            "click": function ($event) {
              _vm.inquiryModal = true;
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-cash-plus")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Create Claim Ticket")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on,
          attrs = _ref5.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "fab": "",
            "dark": "",
            "color": "indigo"
          },
          on: {
            "click": function ($event) {
              return _vm.updatelock('unlock');
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-lock-off-outline")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Unlock Order")])]), _vm.isCompany ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on,
          attrs = _ref6.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', {
          attrs: {
            "fab": "",
            "color": "danger",
            "disabled": _vm.orderIsFullyPaid || !_vm.userCanInvoice,
            "dark": !(_vm.orderIsPaid || !_vm.userCanInvoice)
          },
          on: {
            "click": function ($event) {
              _vm.debitOrderModal = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v("mdi-cash-check")])], 1)], 1)];
      }
    }], null, false, 3339876066)
  }, [_c('span', [_vm._v("Debit the Order via Invoice ")])]) : _vm._e(), _vm.userRoles.includes('Role.Admin') ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref7) {
        var on = _ref7.on,
          attrs = _ref7.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "fab": "",
            "color": "blue-grey",
            "disabled": _vm.orderIsAnonymized,
            "dark": !_vm.orderIsAnonymized
          },
          on: {
            "click": _vm.anonymizeOrder
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-incognito")])], 1)];
      }
    }], null, false, 2035608128)
  }, [_c('span', [_vm._v("Anonymize Order")])]) : _vm._e(), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref8) {
        var on = _ref8.on,
          attrs = _ref8.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "fab": "",
            "disabled": _vm.orderIsPaid,
            "dark": !_vm.orderIsPaid,
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.archiveOrder();
            }
          }
        }, 'v-btn', attrs, false), on), [_vm.isArchivedOrder ? _c('v-icon', [_vm._v("mdi-archive-off")]) : _c('v-icon', [_vm._v("mdi-archive-arrow-down")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.isArchivedOrder ? 'Unarchive order' : 'Archive order'))])])], 1), _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    model: {
      value: _vm.sendLinkModal,
      callback: function ($$v) {
        _vm.sendLinkModal = $$v;
      },
      expression: "sendLinkModal"
    }
  }, [_c('v-overlay', {
    attrs: {
      "value": _vm.$store.state.loading,
      "absolute": "",
      "color": "white",
      "opacity": 0.8
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64",
      "color": "accent"
    }
  })], 1), _c('v-container', [_c('v-card', {
    attrs: {
      "disabled": _vm.$store.state.loading
    }
  }, [_c('v-flex', {
    staticClass: "mb-12"
  }), _c('base-material-card', {
    attrs: {
      "color": "warning",
      "icon": "mdi-alert-circle-outline"
    },
    scopedSlots: _vm._u([{
      key: "after-heading",
      fn: function () {
        return [_c('div', {
          staticClass: "ml-auto body-3"
        }, [_vm._v(" Send " + _vm._s(_vm.dialogTitle) + " "), _c('h3', [_vm._v(" Are you sure to send the " + _vm._s(_vm.dialogTitle) + " to the customer? ")])])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "red",
            "outlined": "",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              _vm.sendLinkModal = false;
            }
          }
        }, [_vm._v("Not Now ")]), _c('v-spacer'), _vm.sendLinkModal && _vm.dialogMethod !== 'sendOrderPaylink' ? _c('v-btn', {
          attrs: {
            "color": "accent",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('get-files', _vm.language.value ? _vm.language.value : _vm.language);
            }
          }
        }, [_vm._v("View Tickets ")]) : _vm._e(), _c('v-btn', {
          attrs: {
            "color": "success",
            "x-large": ""
          },
          on: {
            "click": _vm.sendAction
          }
        }, [_vm._v("Send ")])];
      },
      proxy: true
    }])
  }, [_c('v-divider', {
    staticClass: "mt-2"
  }), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_vm.dialogTitle === 'order confirmation' && _vm.orderContainsRailPasses ? _c('div', {
    staticClass: "text-h6 mb-2 font-weight-bold"
  }, [_vm._v(" Interrail passes will be attached to the e-mail. ")]) : _vm._e(), _vm.dialogTitle === 'tickets' ? _c('div', [_c('p', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v(" Tickets will be sent to the e-mail address/phone number of each passenger. ")]), _vm.orderContainsRailPasses ? _c('div', {
    staticClass: "text-h6 font-weight-bold blue--text"
  }, [_vm._v(" For Interrail passes, the order confirmation mail will be re-sent automatically. ")]) : _vm._e()]) : _c('div', [_c('v-row', [_vm.customer ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_vm._v(" Customer's E-mail: "), _c('b', [_vm._v(" " + _vm._s(_vm.customer.email))])]) : _vm._e(), this.isactiveLang ? _c('v-col', {
    staticClass: "mb-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.languages,
      "item-value": "value",
      "persistent-hint": "",
      "label": "Select language",
      "return-object": ""
    },
    model: {
      value: _vm.language,
      callback: function ($$v) {
        _vm.language = $$v;
      },
      expression: "language"
    }
  })], 1) : _vm._e(), _vm.dialogTitle === 'payment' && (_vm.isCompany || _vm.customer.company) ? _c('v-col', [_c('v-switch', {
    attrs: {
      "label": `Offer Only`,
      "messages": `Only the order details/preview will be shown, and there will be no payment options. Order will have to be debited manually via invoice through Mango if the customer accepts the offer.`,
      "readonly": _vm.offerMode
    },
    model: {
      value: _vm.offerMode,
      callback: function ($$v) {
        _vm.offerMode = $$v;
      },
      expression: "offerMode"
    }
  })], 1) : _vm._e()], 1)], 1)])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    model: {
      value: _vm.inquiryModal,
      callback: function ($$v) {
        _vm.inquiryModal = $$v;
      },
      expression: "inquiryModal"
    }
  }, [_c('v-container', [_c('v-card', {
    attrs: {
      "disabled": _vm.$store.state.loading
    }
  }, [_c('v-flex', {
    staticClass: "mb-12"
  }), _c('base-material-card', {
    attrs: {
      "color": "warning",
      "icon": "mdi-alert-circle-outline"
    },
    scopedSlots: _vm._u([{
      key: "after-heading",
      fn: function () {
        return [_c('div', {
          staticClass: "ml-auto"
        }, [_c('div', {
          staticClass: "body-3 grey--text font-weight-light",
          domProps: {
            "textContent": _vm._s('Create a claim')
          }
        }), _c('h3', [_vm._v("Create a claim")])])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "red",
            "outlined": "",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              _vm.inquiryModal = false;
            }
          }
        }, [_vm._v("Not Now ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "loading": _vm.$store.state.loading,
            "color": "success",
            "x-large": ""
          },
          on: {
            "click": _vm.createInquiry
          }
        }, [_vm._v("Create Claim ")])];
      },
      proxy: true
    }])
  }, [_c('v-divider', {
    staticClass: "mt-2"
  }), _c('v-card-text', [_c('ValidationObserver', {
    ref: "inquiryObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        var Validate = _ref9.Validate,
          reset = _ref9.reset;
        return [_c('ValidationProvider', {
          attrs: {
            "name": "description",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref10) {
              var errors = _ref10.errors;
              return [_c('v-textarea', {
                attrs: {
                  "filled": "",
                  "label": "Description",
                  "error-messages": errors,
                  "clearable": ""
                },
                model: {
                  value: _vm.inquiryDesc,
                  callback: function ($$v) {
                    _vm.inquiryDesc = $$v;
                  },
                  expression: "inquiryDesc"
                }
              })];
            }
          }], null, true)
        })];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _vm.isCompany ? _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    model: {
      value: _vm.debitOrderModal,
      callback: function ($$v) {
        _vm.debitOrderModal = $$v;
      },
      expression: "debitOrderModal"
    }
  }, [_c('div', [_c('v-card', {
    staticClass: "elevation-0",
    attrs: {
      "disabled": _vm.$store.state.loading
    }
  }, [_c('v-flex', {
    staticClass: "pb-12"
  }), _c('base-material-card', {
    attrs: {
      "color": "warning",
      "icon": "mdi-alert-circle-outline"
    },
    scopedSlots: _vm._u([{
      key: "after-heading",
      fn: function () {
        return [_c('div', {
          staticClass: "ml-auto"
        }, [_c('div', {
          staticClass: "body-3 grey--text font-weight-light",
          staticStyle: {
            "margin-top": "-20px"
          },
          domProps: {
            "textContent": _vm._s('Debit the order')
          }
        }), _c('h3')])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "red",
            "outlined": "",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              _vm.debitOrderModal = false;
            }
          }
        }, [_vm._v("Not Now ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "loading": _vm.$store.state.loading,
            "color": "success",
            "x-large": ""
          },
          on: {
            "click": _vm.debitTheOrder
          }
        }, [_vm._v("Debit ")])];
      },
      proxy: true
    }], null, false, 3828138771)
  }, [_c('v-divider', {
    staticClass: "mt-2"
  }), _c('v-card-text', [_c('h2', {
    staticClass: "mb-2"
  }), _c('h3', [_vm._v(" The confirmation mail and tickets WILL be sent automatically. ")])])], 1)], 1)], 1)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }