<template>
  <div>
    <toolbarTitle
      :title="
        invoice.fortnoxNumber
          ? 'Invoice Info #' + invoice.fortnoxNumber
          : 'Invoice Info'
      "
      class="mb-5"
      :titleRed="
        `(` + statusList.find((item) => item.id === invoice.status).label + `)`
      "
    />
    <div class="elevation-1 rounded">
      <v-card v-if="invoice" class="pa-4">
        <v-row>
          <v-col class="py-0" style="min-width: 250px">
            <v-card-title class="accent--text medium">
              <span class="text-body-2 font-weight-bold">Company Name</span>
            </v-card-title>
            <v-card-subtitle class="font-weight-bold pb-0">
                <v-icon    @click="
                  companyDetail = invoice.company;
                  initCustomersByCompanyId(invoice.company.id);
                  detailDialog = true;
                " color="primary" class="pa-1"  small> mdi-domain </v-icon>
              {{
                invoice.company.name ? invoice.company.name : "---"
              }}</v-card-subtitle
            >
          </v-col>
          <v-col class="py-0" style="min-width: 250px">
            <v-card-title class="accent--text medium">
              <span class="text-body-2 font-weight-bold">Customer</span>
            </v-card-title>
            <v-card-subtitle
              v-if="invoice.customer"
              class="font-weight-bold pb-0"
            >
              <v-tooltip color="black" bottom style="cursor: pointer">
                <template v-slot:activator="{ on, attrs }">
                  <b v-bind="attrs" v-on="on"
                    >{{
                      invoice.customer.companyCustomerNumber
                        ? "IATA Number " +
                          invoice.customer.companyCustomerNumber
                        : ""
                    }}
                    {{ invoice.customer.firstName }}
                    {{ invoice.customer.lastName }}
                  </b>
                  <div class="text-body-2">
                    {{ invoice.customer.email }}
                  </div>
                </template>
                <div>
                  {{ invoice.customer.email }}
                </div>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-subtitle v-else class="font-weight-bold">
              <v-tooltip color="black" bottom style="cursor: pointer">
                <template v-slot:activator="{ on, attrs }">
                  <b v-bind="attrs" v-on="on"
                    >{{ invoice.order.customer.firstName }}
                    {{ invoice.order.customer.lastName }}</b
                  >
                </template>
                <div>
                  {{ invoice.order.customer.email }}
                </div>
              </v-tooltip>
            </v-card-subtitle>
          </v-col>

          <v-col class="py-0" style="min-width: 250px">
            <v-card-title class="accent--text medium">
              <span class="text-body-2 font-weight-bold">
                Order Debited (GMT +{{ gmt }})</span
              >
            </v-card-title>
            <v-card-subtitle class="font-weight-bold pb-0">
              {{ invoice.created | localizeUTCAndGetDate }}
              <v-icon  small class="third--text clock mb-1"
                >mdi-clock-time-eight-outline</v-icon
              >
              {{ invoice.created | localizeUTCAndGetTime }}
              <div v-if="invoice.debitedBy" class="text-body-2">
                by {{ invoice.debitedBy ? (invoice.debitedBy.fullName ?? invoice.debitedBy.name) : "" }}
              </div>
            </v-card-subtitle>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" style="min-width: 250px">
            <v-card-title class="accent--text medium">
              <span class="text-body-2 font-weight-bold">
                Must be Paid Before</span
              >
            </v-card-title>
            <v-card-subtitle
              class="font-weight-bold"
              v-if="invoice.invoicedInExternalSystem"
              >{{
                invoice.invoicedInExternalSystem
                  | localizeUTCAddDaysAndGetDate(
                    invoice.company.allowedDaysToPay
                  )
              }}</v-card-subtitle
            >
            <v-card-subtitle class="font-weight-bold" v-else>
              Not invoiced yet
            </v-card-subtitle>
          </v-col>
          <v-col class="py-0" style="min-width: 250px">
            <v-card-title class="accent--text medium">
              <span class="text-body-2 font-weight-bold"> Reference</span>
            </v-card-title>
            <v-card-subtitle class="font-weight-bold">{{
              invoice.reference ? invoice.reference : "-"
            }}</v-card-subtitle>
          </v-col>

          <v-col class="py-0" style="min-width: 250px">
            <v-card-title class="accent--text medium">
              <span class="text-body-2 font-weight-bold">
                Invoice Events

                </span
              >
            </v-card-title>
            <v-card-subtitle class="font-weight-bold">
              <v-tooltip color="black" bottom style="cursor: pointer">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="font-weight-medium one-line threeDots"
                    style="max-width: 250px"
                    v-bind="attrs"
                    v-on="on"
                  >
                     <v-icon small     @click="eventDetail = invoice" color="primary " class="pa-1">mdi-history</v-icon> {{ lastEvent(invoice.events[0]).description }}
                  </div>
                </template>
                <div>{{ lastEvent(invoice.events[0]).description }}</div>
              </v-tooltip>
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-dialog
      v-model="detailDialog"
      :loading="isLoading"
      width="1000px"
      class="pa-5"
      @click:outside="detailDialog = false"
    >
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title class="ml-2"
            >Company :
            {{ companyDetail && companyDetail.name }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <companyDetail
          v-if="companyDetail"
          :company="companyDetail"
          :loading="isLoading"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="eventDetail"
      v-model="eventDetail"
      @click:outside="eventDetail = false"
      max-width="700px"
    >
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title>Invoice Events History</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="eventDetail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-4 py-4">
          <v-row>
            <v-col>
              <v-card-text
                style="max-height: 500px; overflow: auto"
                class="py-0 elevation-2 rounded-lg"
              >
                <v-timeline align-top dense>
                  <v-timeline-item
                    v-for="(event, index) in eventDetail.events"
                    :key="index"
                    small
                  >
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <strong>{{ event.datetime }}</strong>
                      </v-col>
                      <v-col>
                        <strong>{{ event.user.fullName ?? event.user.name }}</strong>
                        <div class="text-caption">
                          {{ event.description }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import companyDetail from "@/components/info/companyDetail";
import toolbarTitle from "@/components/common/toolbarTitle";
export default {
  components: {
    toolbarTitle,
    companyDetail,
  },
  data: () => ({
    gmt: null,
    isLoading: false,
    eventDetail: false,
    companyDetail: null,
    detailDialog: false,
    statusList: [
      {
        label: "Pending",
        id: -1,
      },
      {
        label: "Pending",
        id: 0,
      },
      {
        label: "Invoiced",
        id: 1,
      },
      {
        label: "Paid",
        id: 2,
      },
      {
        label: "Accounted",
        id: 3,
      },
    ],
  }),
  methods: {
    async initCustomersByCompanyId(companyId) {
      try {
        this.isLoading = true;
        const res = await Trainplanet.getCustomerList({ companyId });
        this.companyDetail.customers = res.customers;
      } catch (error) {
        await this.$store.dispatch(
          "error",
          error.response.data.message || error.message
        );
      } finally {
        this.isLoading = false;
      }
    },

    lastEvent(event) {
      let description;
      if (event !== undefined) {
        //some inquiry might does not have those info so always check
        if (event.description !== undefined) description = event.description;
      }
      return {
        description: description,
      };
    },
  },
  props: {
    invoice: [],
  },
  mounted() {
    this.gmt = moment().utcOffset() / 60;
  },
};
</script>

<style lang="scss" scoped>
</style>
