<template>
  <div class="pa-4">
    <v-card v-if="sortedTickets && sortedTickets.length > 0">
      <toolbarTitle title="Order Tickets" />
      <v-list dense>
        <v-list-item v-for="(item, index) in sortedTickets" :key="index">
          <v-list-item-content class="font-weight-bold">
            {{
              item.category == "ticket"
                ? "Ticket" + " " + item.fileName
                : fixFilename(item.fileName)
            }}</v-list-item-content
          >
          <v-list-item-content class="justify-end">
            <v-btn
              color="accent"
              class="white--text"
              style="max-width: 130px"
              @click="openFile(item.accessUrl)"
            >
              View File
              <v-icon right dark> mdi-eye </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card v-if="sortedFiles && sortedFiles.length > 0">
      <toolbarTitle title="Order Files" />
      <v-list dense>
        <v-list-item v-for="(item, index) in sortedFiles" :key="index">
          <v-list-item-content class="font-weight-bold">
            {{
              item.category == "ticket"
                ? "Ticket" + " " + item.fileName
                : fixFilename(item.fileName)
            }}</v-list-item-content
          >
          <v-list-item-content class="justify-end">
            <v-btn
              color="accent"
              class="white--text"
              style="max-width: 130px"
              @click="openFile(item.accessUrl)"
            >
              View File
              <v-icon right dark> mdi-eye </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import toolbarTitle from "@/components/common/toolbarTitle";
export default {
  components: {
    toolbarTitle,
  },
  props: ["files"],
  data() {
    return {
      fileList:null,
      sortedTickets: null,
      sortedFiles: null,
    };
  },
  mounted() {
    this.fileList = this.files
    this.sortedTickets = this.sortTickets(this.fileList);
    this.sortedFiles = this.sortFiles(this.fileList);
  },
  methods: {
    openFile(value) {
      window.open(value);
    },
    fixFilename(filename) {
      return filename.replaceAll("-", " ").replace(".pdf", "");
    },
    sortFiles(fileList) {
      const sortedFileList = fileList.map((item) => ({
        rowId:
          item.category == "ticket"
            ? 1
            : item.category == "mobile_pass"
            ? 2
            : item.category == "gift_card"
            ? 3
            : item.category == "receipt"
            ? 4
            : item.category == "information"
            ? 5
            : 99,
        ...item,
      }));
      return sortedFileList
        .sort(function (x, y) {
          return x.rowId - y.rowId;
        })
        .filter((x) => x.category == "receipt" || x.category == "information");
    },
    sortTickets(fileList) {
      const sortedFileList = fileList.map((item) => ({
        rowId:
          item.category == "ticket"
            ? 1
            : item.category == "mobile_pass"
            ? 2
            : item.category == "gift_card"
            ? 3
            : item.category == "receipt"
            ? 4
            : item.category == "information"
            ? 5
            : 99,
        ...item,
      }));
      return sortedFileList
        .sort(function (x, y) {
          return x.rowId - y.rowId;
        })
        .filter(
          (x) =>
            x.category == "ticket" ||
            x.category == "mobile_pass" ||
            x.category == "gift_card"
        );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
